import Button from "../UI/Button";
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

function ContactSection() {

    const form = useRef();
    const [loading, setLoading] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();
          setLoading(true)
        emailjs.sendForm('service_8u5uzec', 'template_e1c9lmc', form.current, 'WEQywO34Q5EZyhEpd')

          .then((result) => {
            setLoading(false)
            alert('Message sent successfully')
          })
          .catch((error) => {
            setLoading(false)
            alert('Error')
          });
      };

    return (
        <div className="container mx-auto pt-[100px] pb-[100px] lg:pb-[180px]">
            <h2 className="font-semibold text-[26px] text-[#272728] mb-8">How Can I Help You?</h2>
            <form ref={form} onSubmit={sendEmail}>
                <div className="grid md:grid-cols-2 gap-7">
                    <div className="mb-6">
                        <span className="block text-base font-semibold text-[#09213D] mb-2">Your Name</span>
                        <input className="h-[66px] bg-[#FAFAFC] border border-[#DFDFE8] rounded-[15px] px-[26px] w-full text-[15px] placeholder-[#999999] text-[#272728] focus:!border-[#FC6D2B] focus:outline-[#FC6D2B] transition-all" placeholder="Your Name" type="text" name="name"/>
                    </div>
                    <div className="mb-6">
                        <span className="block text-base font-semibold text-[#09213D] mb-2">Your Email</span>
                        <input className="h-[66px] bg-[#FAFAFC] border border-[#DFDFE8] rounded-[15px] px-[26px] w-full text-[15px] placeholder-[#999999] text-[#272728] focus:!border-[#FC6D2B] focus:outline-[#FC6D2B] transition-all" placeholder="Your Email Address" type="email" name="email"/>
                    </div>
                </div>
                <div className="mb-6">
                    <span className="block text-base font-semibold text-[#09213D] mb-2">Subject</span>
                    <input className="h-[66px] bg-[#FAFAFC] border border-[#DFDFE8] rounded-[15px] px-[26px] w-full text-[15px] placeholder-[#999999] text-[#272728] focus:!border-[#FC6D2B] focus:outline-[#FC6D2B] transition-all" placeholder="Subject" type="text" name="subject"/>
                </div>
                <div className="mb-12">
                    <span className="block text-base font-semibold text-[#09213D] mb-2">Your Message</span>
                    <textarea className="h-[180px] bg-[#FAFAFC] border border-[#DFDFE8] rounded-[15px] px-[26px] py-6 w-full text-[15px] placeholder-[#999999] text-[#272728] focus:!border-[#FC6D2B] focus:outline-[#FC6D2B] transition-all resize-none" placeholder="Write Message here..." type="text" name="message"/>
                </div>
                <div className={'contact-button mb-12'}>
                    <Button buttonType={'submit'} buttonText={loading ? 'Please wait..' : 'Send Message'}/>
                </div>
                <div className="mt-[56px] flex items-center justify-center gap-8">
                    <p className="font-medium text-base text-[#04030B]">Follow Me:</p>
                    <div className="flex items-start gap-[30px]">
                        <a href="https://www.linkedin.com/in/dayspringjohnson/?fbclid=IwAR1NBvGpFwDH2puRt89zN4TdYzNBzlXQ23T3BxutDbrhSTsrNpW1xG21NE4" target={'_blank'} rel={'noreferrer'}>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.98741 2.00089C3.98715 2.5313 3.77685 3.03987 3.40277 3.41473C3.02869 3.7896 2.52147 4.00005 1.99271 3.99978C1.46395 3.99952 0.95694 3.78856 0.583239 3.41332C0.209529 3.03808 -0.000268892 2.5293 2.58651e-07 1.99889C0.000269409 1.46849 0.210566 0.959918 0.584645 0.585048C0.958724 0.210189 1.46594 -0.000259745 1.9947 2.40602e-07C2.52347 0.000270226 3.03048 0.211229 3.40418 0.586468C3.77789 0.961708 3.98768 1.47049 3.98741 2.00089ZM4.04723 5.4807H0.0598117V18H4.04723V5.4807ZM10.3473 5.4807H6.37986V18H10.3074V11.4304C10.3074 7.77056 15.0624 7.43058 15.0624 11.4304V18H19V10.0704C19 3.90079 11.9622 4.13078 10.3074 7.16059L10.3473 5.4807Z" fill="#04030B"/>
</svg>

                        </a>
                        <a href="https://twitter.com/DayCyberwox?fbclid=IwAR1NBvGpFwDH2puRt89zN4TdYzNBzlXQ23T3BxutDbrhSTsrNpW1xG21NE4" target="_blank" rel={'noreferrer'}>
                        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_1127)">
<path d="M20.1962 2.68105C19.4689 3.00005 18.694 3.21149 17.8862 3.31416C18.7173 2.81794 19.3516 2.03816 19.6498 1.09827C18.8749 1.56027 18.0194 1.88661 17.1076 2.06872C16.3718 1.28527 15.3232 0.800049 14.1792 0.800049C11.9596 0.800049 10.1727 2.60161 10.1727 4.81016C10.1727 5.12794 10.1996 5.43349 10.2656 5.72438C6.93262 5.56183 3.9834 3.96438 2.00218 1.53094C1.65629 2.13105 1.4534 2.81794 1.4534 3.55738C1.4534 4.94583 2.1684 6.17661 3.23418 6.88916C2.59006 6.87694 1.95818 6.68994 1.42284 6.39538C1.42284 6.40761 1.42284 6.42349 1.42284 6.43938C1.42284 8.38761 2.81251 10.0058 4.63484 10.3786C4.30851 10.4678 3.95284 10.5106 3.58373 10.5106C3.32706 10.5106 3.06795 10.4959 2.82473 10.4422C3.34418 12.0298 4.81818 13.197 6.57084 13.2349C5.20684 14.3019 3.47495 14.9448 1.60006 14.9448C1.27129 14.9448 0.955958 14.9301 0.640625 14.8898C2.41651 16.035 4.52118 16.6889 6.79084 16.6889C14.1682 16.6889 18.2015 10.5778 18.2015 5.28072C18.2015 5.10349 18.1954 4.93238 18.1868 4.76249C18.9825 4.19783 19.651 3.49261 20.1962 2.68105Z" fill="#04030B"/>
</g>
<defs>
<clipPath id="clip0_112_1127">
<rect width="21" height="16" fill="white" transform="translate(0 0.800049)"/>
</clipPath>
</defs>
</svg>
                        </a>
                        <a href="https://www.instagram.com/DayCyberwox/?fbclid=IwAR2QMOkHInPPTOV_nygxdL4X3WjOIQ7niH5pdQASypCJGzqVxE_nwsY3qCc" target="_blank" rel={'noreferrer'}>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.52539 0H13.2754C16.3816 0 18.9004 2.51887 18.9004 5.625V12.375C18.9004 15.4812 16.3816 18 13.2754 18H6.52539C3.41926 18 0.900391 15.4812 0.900391 12.375V5.625C0.900391 2.51887 3.41926 0 6.52539 0ZM13.2754 16.3125C15.4467 16.3125 17.2129 14.5462 17.2129 12.375V5.625C17.2129 3.45375 15.4467 1.6875 13.2754 1.6875H6.52539C4.35414 1.6875 2.58789 3.45375 2.58789 5.625V12.375C2.58789 14.5462 4.35414 16.3125 6.52539 16.3125H13.2754Z" fill="#04030B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.40039 9C5.40039 6.51487 7.41527 4.5 9.90041 4.5C12.3856 4.5 14.4004 6.51487 14.4004 9C14.4004 11.4852 12.3856 13.5 9.90041 13.5C7.41527 13.5 5.40039 11.4852 5.40039 9ZM7.08789 9C7.08789 10.5502 8.35014 11.8125 9.90041 11.8125C11.4507 11.8125 12.7129 10.5502 12.7129 9C12.7129 7.44862 11.4507 6.1875 9.90041 6.1875C8.35014 6.1875 7.08789 7.44862 7.08789 9Z" fill="#04030B"/>
<path d="M14.7383 4.76224C15.0695 4.76224 15.3379 4.49378 15.3379 4.16261C15.3379 3.83145 15.0695 3.56299 14.7383 3.56299C14.4071 3.56299 14.1387 3.83145 14.1387 4.16261C14.1387 4.49378 14.4071 4.76224 14.7383 4.76224Z" fill="#04030B"/>
</svg>
                        </a>
                        <a href="https://www.youtube.com/@DayCyberwox/videos" target="_blank" rel={'noreferrer'}>
                        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_1135)">
<path d="M0 12.3415C0 9.8076 0 7.27268 0 4.7388C0.0517331 4.31873 0.0931195 3.89658 0.156234 3.47858C0.385929 1.97005 1.18055 0.936419 2.66839 0.463579C3.67098 0.144904 4.7046 0.00315504 5.74237 0.00212038C10.6674 -0.00408759 15.5934 0.015571 20.5184 0.0466108C21.4102 0.0528188 22.2897 0.218365 23.133 0.529798C24.1811 0.916761 24.8981 1.62757 25.2426 2.70155C25.5075 3.52825 25.5851 4.38288 25.5923 5.23957C25.612 7.50652 25.6192 9.7745 25.5954 12.0414C25.5841 13.0658 25.4589 14.0828 25.1123 15.0606C24.6705 16.3073 23.822 17.1299 22.5691 17.5448C21.5944 17.8676 20.5846 17.9814 19.5685 17.9856C15.3678 18.0021 11.1671 17.9969 6.96534 18.0011C5.82204 18.0021 4.68495 17.94 3.57475 17.6359C2.53595 17.3513 1.62856 16.8619 0.997413 15.9525C0.512157 15.252 0.284532 14.4563 0.156234 13.6276C0.0900155 13.2003 0.0506984 12.7698 0 12.3415ZM9.85929 8.87227C9.85929 9.75587 9.85204 10.6395 9.86549 11.522C9.8686 11.7155 9.90585 11.9276 9.99483 12.0952C10.2131 12.506 10.6881 12.6012 11.1681 12.3518C12.8805 11.461 14.5898 10.566 16.3011 9.67517C16.6332 9.50238 16.8722 9.27372 16.8712 8.8702C16.8701 8.46772 16.6342 8.24009 16.299 8.0673C14.5991 7.18991 12.9033 6.30424 11.2043 5.42478C10.4635 5.04092 9.86136 5.41029 9.86032 6.24734C9.85722 7.12162 9.85929 7.99695 9.85929 8.87227Z" fill="#04030B"/>
<path d="M9.85952 8.8723C9.85952 7.99698 9.85745 7.12165 9.85952 6.24737C9.86159 5.40929 10.4638 5.04095 11.2035 5.42481C12.9025 6.30531 14.5983 7.18994 16.2982 8.06734C16.6324 8.24012 16.8694 8.46775 16.8704 8.87023C16.8714 9.27375 16.6314 9.50241 16.3003 9.6752C14.5879 10.566 12.8787 11.461 11.1673 12.3519C10.6883 12.6012 10.2134 12.5071 9.99403 12.0953C9.90505 11.9277 9.8678 11.7145 9.86469 11.5221C9.85228 10.6385 9.85952 9.75487 9.85952 8.8723Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_112_1135">
<rect width="25.611" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactSection;
