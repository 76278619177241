import Slider from "react-slick";
import C1 from "../../../assets/img/courses/1.jpg";
import C2 from "../../../assets/img/courses/2.jpg";
import C3 from "../../../assets/img/courses/3.jpg";
import C4 from "../../../assets/img/courses/4.jpg";
import C5 from "../../../assets/img/courses/5.jpg";
import { useRef } from "react";

function Courses() {

    const slider = useRef(null)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: 30,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                infinite: true,
              }
            },
        ]
    };

    return (
        <div className="bg-[#F7F9FA] pt-[100px] lg:pt-[130px] pb-[100px] lg:pb-[155px] overflow-hidden">
            <div className="container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500">
                <div className="lg:flex items-center justify-between gap-5 mb-[30px] lg:mb-[60px]">
                    <h2 className="text-[40px] lg:text-[72px] text-[#272728] font-semibold text-center lg:text-left lg:leading-[80px]">Authored Courses & Labs</h2>
                    <div className="hidden lg:flex items-center justify-center lg:justify-start !gap-5">
                        <div className="border-[#DEDEE7] border-[3px] rounded-full h-14 w-14 flex items-center justify-center cursor-pointer hover:border-[#FC6D2B] hover:bg-[#FC6D2B] transition-all duration-300 group" onClick={() => slider.current.slickPrev()}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="group-hover:fill-white transition-all duration-300" d="M9.08879 15.6395C9.35892 15.3694 9.38347 14.9467 9.16246 14.6488L9.08879 14.5635L2.5257 8.00005L9.08879 1.43661C9.35892 1.16649 9.38347 0.743786 9.16246 0.445919L9.08879 0.360582C8.81867 0.0904565 8.39596 0.0658993 8.0981 0.286911L8.01276 0.360582L0.91131 7.46203C0.641185 7.73216 0.616627 8.15486 0.837639 8.45273L0.91131 8.53806L8.01276 15.6395C8.3099 15.9367 8.79165 15.9367 9.08879 15.6395Z" fill="#272728"/>
</svg>
                        </div>
                        <div className="border-[#DEDEE7] border-[3px] rounded-full h-14 w-14 flex items-center justify-center cursor-pointer hover:border-[#FC6D2B] hover:bg-[#FC6D2B] transition-all duration-300 group" onClick={() => slider.current.slickNext()}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="group-hover:fill-white transition-all duration-300" d="M0.91133 15.6395C0.641205 15.3694 0.616648 14.9467 0.83766 14.6488L0.91133 14.5635L7.47442 8.00005L0.91133 1.43661C0.641205 1.16649 0.616648 0.743786 0.83766 0.445919L0.91133 0.360582C1.18146 0.0904565 1.60416 0.0658993 1.90202 0.286911L1.98736 0.360582L9.08881 7.46203C9.35894 7.73216 9.38349 8.15486 9.16248 8.45273L9.08881 8.53806L1.98736 15.6395C1.69022 15.9367 1.20847 15.9367 0.91133 15.6395Z" fill="#272728"/>
</svg>
                        </div>
                    </div>
                </div>
                <Slider {...settings} ref={slider}>
                    <a href={'https://www.linkedin.com/learning/introduction-to-aws-threat-detection?fbclid=IwAR2kbPcy8-sXgDivq5rti0-QSIMcSj7MduOD-vpHJglCYPzgZE_9BQP-_cU'} target={'_blank'} rel={'noreferrer'} className="carousel_item">
                        <div className="carousel_img relative">
                            <img src={C1} alt="C1"/>
                            <div className={'text-base text-[#272728] !py-3 !px-5 font-medium border border-[#B4B4B4B2] absolute left-[30px] top-[30px] rounded-[66px] bg-white'}>Linkedin Learning</div>
                        </div>
                        <h3>Introduction AWS Threat Detection</h3>
                        <p>In this course, I teach the fundamental skills and techniques of threat detection, analysis, and monitoring using the built-in tools of AWS.</p>
                    </a>
                    <a href={'https://cyberwoxacademy.com/building-a-cybersecurity-homelab-for-detection-monitoring/?fbclid=IwAR36cLPDyXF-RU_-6WSH-fNE8rtdPlOKGj5Y8l5_hStYPa2wqycCL_2dNzw'} target={'_blank'} rel={'noreferrer'} className="carousel_item">
                        <div className="carousel_img relative">
                            <img src={C2} alt="C2"/>
                            <div className={'text-base text-[#272728] !py-3 !px-5 font-medium border border-[#B4B4B4B2] absolute left-[30px] top-[30px] rounded-[66px] bg-white'}>Cyberwox</div>
                        </div>
                        <h3>Cybersecurity Detection & Monitoring Lab</h3>
                        <p>This lab teaches you how to build a homelab for Cybersecurity Detection & Monitoring using free and open source tools.</p>
                    </a>
                    <a href={'https://app.letsdefend.io/challenge/'} target={'_blank'} rel={'noreferrer'} className="carousel_item">
                        <div className="carousel_img relative">
                            <img src={C3} alt="C3"/>
                            <div className={'text-base text-[#272728] !py-3 !px-5 font-medium border border-[#B4B4B4B2] absolute left-[30px] top-[30px] rounded-[66px] bg-white'}>LetsDefend</div>
                        </div>
                        <h3>AWS CloudTrail - AWS DFIR Lab</h3>
                        <p>This lab guides you through a common AWS data exfiltration attack and how to investigate it.</p>
                    </a>
                    <a href={'https://app.letsdefend.io/challenge/'} target={'_blank'} rel={'noreferrer'} className="carousel_item">
                        <div className="carousel_img relative">
                            <img src={C4} alt="C4"/>
                            <div className={'text-base text-[#272728] !py-3 !px-5 font-medium border border-[#B4B4B4B2] absolute left-[30px] top-[30px] rounded-[66px] bg-white'}>LetsDefend</div>
                        </div>
                        <h3>AWS PerSEStence - AWS DFIR Lab</h3>
                        <p>This labs guides you through various AWS persistence and privilege escalation attacks and how to investigate them.</p>
                    </a>
                    <a href={'https://blueteamlabs.online'} target={'_blank'} rel={'noreferrer'} className="carousel_item">
                        <div className="carousel_img relative">
                            <img src={C5} alt="C5"/>
                            <div className={'text-base text-[#272728] !py-3 !px-5 font-medium border border-[#B4B4B4B2] absolute left-[30px] top-[30px] rounded-[66px] bg-white'}>BTLO</div>
                        </div>
                        <h3>Grumpy Dev - AWS DFIR Lab</h3>
                        <p>This lab guides you through a very common AWS exfiltration attack and how to investigate it.</p>
                    </a>
                </Slider>
                <div className="mt-[30px] flex lg:hidden items-center justify-center lg:justify-start !gap-5">
                        <div className="border-[#DEDEE7] border-[3px] rounded-full h-14 w-14 flex items-center justify-center cursor-pointer hover:border-[#FC6D2B] hover:bg-[#FC6D2B] transition-all duration-300 group" onClick={() => slider.current.slickPrev()}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="group-hover:fill-white transition-all duration-300" d="M9.08879 15.6395C9.35892 15.3694 9.38347 14.9467 9.16246 14.6488L9.08879 14.5635L2.5257 8.00005L9.08879 1.43661C9.35892 1.16649 9.38347 0.743786 9.16246 0.445919L9.08879 0.360582C8.81867 0.0904565 8.39596 0.0658993 8.0981 0.286911L8.01276 0.360582L0.91131 7.46203C0.641185 7.73216 0.616627 8.15486 0.837639 8.45273L0.91131 8.53806L8.01276 15.6395C8.3099 15.9367 8.79165 15.9367 9.08879 15.6395Z" fill="#272728"/>
</svg>
                        </div>
                        <div className="border-[#DEDEE7] border-[3px] rounded-full h-14 w-14 flex items-center justify-center cursor-pointer hover:border-[#FC6D2B] hover:bg-[#FC6D2B] transition-all duration-300 group" onClick={() => slider.current.slickNext()}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="group-hover:fill-white transition-all duration-300" d="M0.91133 15.6395C0.641205 15.3694 0.616648 14.9467 0.83766 14.6488L0.91133 14.5635L7.47442 8.00005L0.91133 1.43661C0.641205 1.16649 0.616648 0.743786 0.83766 0.445919L0.91133 0.360582C1.18146 0.0904565 1.60416 0.0658993 1.90202 0.286911L1.98736 0.360582L9.08881 7.46203C9.35894 7.73216 9.38349 8.15486 9.16248 8.45273L9.08881 8.53806L1.98736 15.6395C1.69022 15.9367 1.20847 15.9367 0.91133 15.6395Z" fill="#272728"/>
</svg>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Courses;
