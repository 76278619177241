import Certificate1 from '../../../assets/img/certifications/1.png';
import Certificate2 from '../../../assets/img/certifications/2.png';
import Certificate3 from '../../../assets/img/certifications/3.png';
import Certificate4 from '../../../assets/img/certifications/4.png';
import Certificate5 from '../../../assets/img/certifications/5.png';
import Certificate6 from '../../../assets/img/certifications/6.png';
import Certificate7 from '../../../assets/img/certifications/7.png';
import Certificate8 from '../../../assets/img/certifications/8.png';
import Certificate9 from '../../../assets/img/certifications/9.png';
import Certificate10 from '../../../assets/img/certifications/10.png';
import Certificate11 from '../../../assets/img/certifications/11.png';
import SecondaryButton from '../../UI/SecondaryButton';
import CertificationItem from './CertificationItem';
import { useState } from 'react';

const imagePerRow = 6;

function CertificationSection() {
    const [next, setNext] = useState(imagePerRow);

    const certificates = [
        {
            certificateNum: '01',
            certificateTitle: 'CompTIA Project+ Certification',
            certificateDate: 'Aug 2022',
            certificateImage: Certificate1
        },
        {
            certificateNum: '02',
            certificateTitle: 'Introduction to Azure Penetration Testing',
            certificateDate: 'Jul 2022',
            certificateImage: Certificate2
        },
        {
            certificateNum: '03',
            certificateTitle: 'Offensive Azure Security Professional',
            certificateDate: 'Jun 2022',
            certificateImage: Certificate3
        },
        {
            certificateNum: '04',
            certificateTitle: 'Blue Team Level 1 (BTL1)',
            certificateDate: 'Feb 2022',
            certificateImage: Certificate4
        },
        {
            certificateNum: '05',
            certificateTitle: 'Foundations of Operationalizing MITRE ATT&CK',
            certificateDate: 'Oct 2021',
            certificateImage: Certificate5
        },
        {
            certificateNum: '06',
            certificateTitle: 'Foundations of Purple Teaming',
            certificateDate: 'Oct 2021',
            certificateImage: Certificate5
        },
        {
            certificateNum: '07',
            certificateTitle: 'LPI Linux Essentials',
            certificateDate: 'Sep 2021',
            certificateImage: Certificate6
        },
        {
            certificateNum: '08',
            certificateTitle: 'ITIL 4 Foundation',
            certificateDate: 'Aug 2021',
            certificateImage: Certificate7
        },
        {
            certificateNum: '09',
            certificateTitle: 'Microsoft Certified: Azure Fundamentals',
            certificateDate: 'May 2021',
            certificateImage: Certificate8
        },
        {
            certificateNum: '10',
            certificateTitle: 'AWS Certified Cloud Practitioner',
            certificateDate: 'Aug 2024',
            certificateImage: Certificate9
        },
        {
            certificateNum: '11',
            certificateTitle: 'CompTIA CySA+',
            certificateDate: 'Jul 2021 · Expires Jul 2024',
            certificateImage: Certificate1
        },
        {
            certificateNum: '12',
            certificateTitle: 'Splunk Core Certified User',
            certificateDate: 'May 2021 · Expires May 2024',
            certificateImage: Certificate10
        },
        {
            certificateNum: '13',
            certificateTitle: 'CompTIA Security+',
            certificateDate: 'Nov 2020 · Expires Nov 2023',
            certificateImage: Certificate1
        },
        {
            certificateNum: '14',
            certificateTitle: 'CompTIA Network+',
            certificateDate: 'Sep 2020 · Expires Sep 2023',
            certificateImage: Certificate1
        },
        {
            certificateNum: '15',
            certificateTitle: 'CompTIA A+',
            certificateDate: 'Jul 2020 · Expired Jul 2023',
            certificateImage: Certificate1
        },
        {
            certificateNum: '16',
            certificateTitle: 'SOC Analyst 1 Elite',
            certificateDate: 'Feb 2021 · Expired Feb 2023',
            certificateImage: Certificate11
        },
    ]

    const handleMoreImage = () => {
        setNext(next + imagePerRow);
    };

    return(
        <div className="container mx-auto py-[100px] lg:py-[180px]">
            <h1 data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500" className="lg:text-[48px] text-[32px] font-semibold text-[#000000] text-center mb-[60px]">Certifications</h1>
            <div className="max-w-[1000px] w-full mx-auto">
                {
                    certificates?.slice(0, next)?.map(el => (
                        <CertificationItem certificateNum={el.certificateNum} certificateTitle={el.certificateTitle} certificateDate={el.certificateDate} certificateImage={el.certificateImage}/>
                    ))
                }
            </div>
            {next < certificates?.length && (
                <div className="text-center">
                    <div className="inline-flex pt-[80px]" onClick={handleMoreImage}>
                        <SecondaryButton buttonText={'Load More'}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CertificationSection;