import {useRef, useState} from "react";

function BlogDetailsCode({code}) {
    const text = useRef();
    const [copied, setCopied] = useState(false)

    const copyButton = () => {
        navigator.clipboard.writeText(text.current.outerText)
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    return (
        <div className={'!px-[30px] !pt-[25px] !pb-[55px] border border-[#DBDBDB] relative group rounded-lg'}>
            <pre className={'text-[19px] text-[#669900] leading-[28.5px] !-mb-[30px] !pb-[30px] pre-code'}>
                <code ref={text}>
                    {code}
                </code>
            </pre>
            <button type={'button'} className={'bg-[#FF8677] rounded-[56px] text-base text-white !px-[26px] flex items-center justify-center absolute top-[25px] right-[30px] h-[38px] opacity-0 transition-all group-hover:!opacity-100'} onClick={copyButton}>{copied ? 'Copied!' : 'Copy'}</button>
        </div>
    )
}

export default BlogDetailsCode;
