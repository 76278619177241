import BreadCrumb from "../Breadcrumb/Breadcrumb";
import ContactSection from "../Contact/ContactSection";
import Layouts from "../Layouts/Layouts";

function Contact() {
    return  (
        <Layouts>
            <BreadCrumb breadcrumbText={'Contact'}/>
            <ContactSection/>
        </Layouts>
    )
}

export default Contact;