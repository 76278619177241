import ReactPlayer from "react-player";

function TalkVideoItem({talkLink, talkTitle, talkImg}) {
    return (
        <div data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="500" className="mb-10">
            {talkImg === null ?
                <div>
                    <ReactPlayer className="react-player rounded-[18px]" width='100%' url={talkLink} controls={true} />
                    <span className="text-[26px] leading-8 text-[#272728] font-semibold pt-[20px] block">{talkTitle}</span>
                </div> :
                <a href={talkLink} target="_blank" rel={'noreferrer'}>
                    <div className="h-[360px] bg-[#DDDEE2] rounded-[18px]">
                        <img className="w-full h-full rounded-[18px]" src={talkImg} alt="1"/>
                    </div>
                    <span className="text-[26px] leading-8 text-[#272728] font-semibold pt-[20px] block">{talkTitle}</span>
                </a>
            }
        </div>
    )
}

export default TalkVideoItem;
