import Layouts from "../Layouts/Layouts";
import BreadCrumb from "../Breadcrumb/Breadcrumb";
import Blogs from "../Blog/Blogs";

function Blog() {
    return (
        <Layouts>
            <BreadCrumb breadcrumbText={'Blogs'}/>
            <Blogs/>
        </Layouts>
    )
}

export default Blog;