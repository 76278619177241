import T1 from '../../assets/img/talks/1.png';
import T2 from '../../assets/img/talks/2.png';
import SecondaryButton from './../UI/SecondaryButton';
import TalkVideoItem from './TalkVideoItem';
import { useState } from 'react';

const imagePerRow = 6;

function TalkVideos() {
    const [next, setNext] = useState(imagePerRow);

    const talkVideos = [
        {
            link: 'https://www.youtube.com/watch?v=FCVG7-lFu0Q',
            title: 'Cloud Security Podcast 2023 - Building Google Cloud Threat Detection',
            img: null,
        },
        {
            link: 'https://www.youtube.com/watch?v=orNBBHKayiY',
            title: 'Fwd:CloudSec 2023 - Google Cloud Threat Detection',
            img: null,
        },
        {
            link: 'https://www.youtube.com/watch?v=4fyeOfFuYl0',
            title: 'Microsoft Reactor 2023 - Exfiltrating Data From Linux Workloads',
            img: null,
        },
        {
            link: 'https://www.buzzsprout.com/2050721/12597412-intel-chat-3cx-to-apt43-and-a-deep-dive-on-the-capital-one-breach-with-cloud-threat-detection-engineer-day-johnson',
            title: 'Lima Charlie 2023 - A Deep Dive Into The Capital One Breach',
            img: T1,
        },
        {
            link: 'https://www.youtube.com/watch?v=ZffU722IzsE',
            title: 'Azure Cloud Security Meetup 2022 - Cloud Detection Engineering in Azure',
            img: null,
        },
        {
            link: 'https://github.com/daycyberwox/tcs-2022',
            title: 'Texas Cyber Summit 2022 - A Chaotic Cause for Detection Engineering',
            img: T2,
        },
        {
            link: 'https://www.youtube.com/watch?v=N4vmxtKo_yA',
            title: 'Figurs Fireside 2022 - Becoming a Cloud Threat Detection Engineer at 20',
            img: null,
        },
        {
            link: 'https://www.youtube.com/watch?v=8Zc7f_4M6JE',
            title: 'Cloud Security Podcast 2021 - Threat Analysts Skills',
            img: null,
        },
    ]

    const handleMoreImage = () => {
        setNext(next + imagePerRow);
    };

    return (
        <div className="container mx-auto pt-[100px] pb-[100px] lg:pb-[180px]">
            <div className="grid md:grid-cols-2 gap-x-7">
            {
                talkVideos?.slice(0, next)?.map(el => (
                <TalkVideoItem talkLink={el.link} talkTitle={el.title} talkImg={el.img}/>
            ))}
            </div>
            {next < talkVideos?.length && (
                <div className="text-center">
                    <div className="inline-flex pt-[40px]" onClick={handleMoreImage}>
                        <SecondaryButton buttonText={'Load More'}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TalkVideos;