import { NavLink } from 'react-router-dom';
import Button from '../UI/Button';
import { useNavigate } from 'react-router-dom';

function MobileHeader() {

    const navigate = useNavigate();

    const contactForm = () => {
        navigate("/contact");
    }

    return (
        <div className="bg-white absolute right-0 top-[50px] w-[250px] !p-5 mobile-header rounded-[10px] z-[999] lg:hidden">
            <div className="mobile_nav">
                <NavLink to={'/'}>Home</NavLink>
                <NavLink to={'/resume'}>Resume</NavLink>
                <NavLink to={'/shop'}>CWX</NavLink>
                <NavLink to={'/https://cyberwoxacademy.com/'} target="_blank">Academy</NavLink>
                <NavLink to={'/talks'}>Talks</NavLink>
                <NavLink to={'/blogs'}>Blog</NavLink>
            </div>
            <div className={'mobile_btn inline-flex'} onClick={contactForm}>
                <Button buttonText={'Contact'}/>
            </div>
        </div>
    )
}

export default MobileHeader;
