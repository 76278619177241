function CertificationItem({certificateNum, certificateTitle, certificateDate, certificateImage}) {
    return(
        <div data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="500" className="border border-[#E8E8E8] rounded-[18px] px-[18px] py-5 lg:!py-8 lg:!px-12 flex items-center mb-6 last:mb-0">
            <span className="text-[24px] font-bold text-[#0E0E10] md:pr-[120px] hidden lg:block">{certificateNum}</span>
            <div className="w-[80px] !my-5 md:!my-0">
                <img src={certificateImage} alt={certificateImage}/>
            </div>
            <div className="pl-7 lg:pl-[90px]">
                <span className="text-sm lg:text-base text-[#A5A5A5] mb-3">{certificateDate}</span>
                <div className="text-xl md:text-[24px] text-[#272728] leading-8 md:leading-[38px] font-semibold">{certificateTitle}</div>
            </div>
        </div>
    )
}

export default CertificationItem;