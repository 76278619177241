import BreadCrumb from "../Breadcrumb/Breadcrumb";
import Layouts from "../Layouts/Layouts";
import CertificationSection from "../Resume/Certifications/Certification";
import Education from "../Resume/Education/Education";
import ResumeSection from "../Resume/Experience/ResumeSection";
import Projects from "../Resume/Projects/Projects";

function Resume() {
    return (
        <Layouts>
            <BreadCrumb breadcrumbText={'Resume'}/>
            <ResumeSection/>
            <Education/>
            <CertificationSection/>
            <Projects/>
        </Layouts>
    )
}

export default Resume;