import SP1 from '../../../assets/img/sponsors/sp-1.png';
import SP2 from '../../../assets/img/sponsors/sp-2.png';
import SP3 from '../../../assets/img/sponsors/sp-3.png';
import SP4 from '../../../assets/img/sponsors/sp-4.png';
import SP5 from '../../../assets/img/sponsors/sp-5.png';
import SP6 from '../../../assets/img/sponsors/sp-6.png';
import SP7 from '../../../assets/img/sponsors/sp-7.png';
import SP8 from '../../../assets/img/sponsors/sp-8.png';
import SP9 from '../../../assets/img/sponsors/sp-9.png';

function Sponsors() {
    return (
        <div className="pt-[60px] lg:pt-[90px]">
            <div className="container mx-auto" data-aos="fade-up"
             data-aos-easing="linear" data-aos-duration="500">
                <div className="text-base md:text-xl font-medium text-[#858585] mb-7 relative text-center max-w-[mb-5 lg:800px] mx-auto">
                    <div className="h-[1px] bg-[#C5C5C5] absolute left-0 top-3.5 w-full -z-[1] before:absolute before:h-1.5 before:w-1.5 before:bg-[#C5C5C5] before:content-[''] before:rounded-full before:left-0 before:-top-[2.6px] after:absolute after:h-1.5 after:w-1.5 after:bg-[#C5C5C5] after:content-[''] after:rounded-full after:right-0 after:-top-[2.6px] hidden md:block"></div>
                    <span className="bg-white px-7">Companies I've Worked With</span>
                </div>
                <div className="hidden lg:block">
                    <div className="flex items-start justify-between">
                        <div className="h-[96px] flex items-center justify-center"><img src={SP1} alt="1" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP2} alt="2" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP3} alt="3" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP8} alt="8" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP4} alt="4" /></div>
                    </div>
                    <div className="flex items-start justify-center gap-[114px]">
                        <div className="h-[96px] flex items-center justify-center"><img src={SP9} alt="9" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP6} alt="6" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP7} alt="7" /></div>
                        <div className="h-[96px] flex items-center justify-center"><img src={SP5} alt="5" /></div>
                    </div>
                </div>
                <div className="grid justify-items-center grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-[20px] lg:hidden">
                    <div className="h-[96px] flex items-center justify-center"><img src={SP1} alt="1" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP2} alt="2" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP3} alt="3" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP8} alt="8" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP4} alt="4" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP9} alt="9" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP6} alt="6" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP7} alt="7" /></div>
                    <div className="h-[96px] flex items-center justify-center"><img src={SP5} alt="5" /></div>
                </div>
            </div>
        </div>
    )
}

export default Sponsors;