import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function Layouts({children}) {
    return (
        <div>
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}

export default Layouts;