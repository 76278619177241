import { Link } from "react-router-dom";

function BlogItem({blogTitle, blogImg, blogLink, blogDate, target}) {
    return (
        <Link to={blogLink} target={target ? '_blank' : '_self'} data-aos="zoom-in-up" data-aos-easing="linear"
        data-aos-duration="500">
            <div className="mb-6 bg-[#828282] rounded-[18px] h-[330px]">
                <img className="w-full h-full rounded-[18px]" src={blogImg} alt={'Blog1'}/>
            </div>
            <ul className="mb-4 flex items-center gap-[60px]">
                <li className="flex items-center gap-2.5">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7082 9.00045C16.7082 13.2579 13.2573 16.7088 8.99984 16.7088C4.74234 16.7088 1.2915 13.2579 1.2915 9.00045C1.2915 4.74295 4.74234 1.29211 8.99984 1.29211C13.2573 1.29211 16.7082 4.74295 16.7082 9.00045Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.8594 11.4524L8.71777 9.57823V5.53906" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                <span className="text-sm text-[#828282]">{blogDate}</span>
                </li>
            </ul>
            <span className="text-[24px] text-black font-semibold leading-10">{blogTitle}</span>
        </Link>
    )
}

export default BlogItem;
