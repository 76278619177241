import ReactPlayer from "react-player";

function Videos() {
    return (
        <div className="container mx-auto py-[100px] lg:py-[180px]">
            <h2 data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500" className="text-[40px] lg:text-[72px] text-[#272728] font-semibold text-center md:leading-[80px] mb-9 md:mb-[60px]">My Youtube</h2>
            <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500" className="grid md:grid-cols-2 md:gap-7">
                <div className="mb-[42px]">
                    <ReactPlayer className="react-player rounded-[18px]" width='100%' url={'https://www.youtube.com/watch?v=NT83f7Nzhmg'} controls={true} />
                    <span className="text-xl lg:text-[24px] text-[#1B1B1B] font-semibold pt-[20px] block">Are Decentralized VPNs The Future? | Deeper Connect Air Review & Unboxing</span>
                </div>
                <div className="mb-[42px]">
                    <ReactPlayer className="react-player rounded-[18px]" width='100%' url={'https://www.youtube.com/watch?v=frleh0eMXyM'} controls={true} />
                    <span className="text-xl lg:text-[24px] text-[#1B1B1B] font-semibold pt-[20px] block">How To Self Study Cybersecurity</span>
                </div>
            </div>
            <div className="text-center">
                <a href="https://www.youtube.com/@DayCyberwox/videos" target={'_blank'} rel={'noreferrer'} className="text-[24px] text-[#0c0303] py-2.5 px-7 border border-[#111111] rounded-[74px] transition-all duration-300 hover:bg-[#FC6D2B] hover:border-[#FC6D2B] hover:text-white uppercase">Explore Now</a>
            </div>
        </div>
    )
}

export default Videos;
