import Accordion from 'react-bootstrap/Accordion';

function Services() {

    return (
        <div className="pb-[100px] lg:pb-[180px]">
            <div className="container mx-auto">
                <h2 data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500" className="text-[40px] lg:text-[72px] text-[#272728] font-semibold mb-[60px] md:mb-[90px] text-center leading-[44px] lg:!leading-[80px]">What I Do</h2>
                <div>
                <Accordion defaultActiveKey="0" className="custom_accordion">
                    <Accordion.Item data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" eventKey="0">
                        <Accordion.Header>
                            <span className="collapse_num">01</span>
                            <span className="collapse_text">Detection Engineering</span>
                            <span className="collapse_icon collapse_less">View Less</span>
                            <span className="collapse_icon collapse_details">View Details</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>My core skills lie in Detection Engineering and Threat Research for cloud environments, SaaS applications, and identity providers. This work also entails Threat Hunting, Threat Emulation, Incident Response, Security Automation, Data Analysis, Security Logging, and Pipeline Engineering.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" eventKey="1">
                        <Accordion.Header>
                            <span className="collapse_num">02</span>
                            <span className="collapse_text">Cloud DFIR Content Engineering</span>
                            <span className="collapse_icon collapse_less">View Less</span>
                            <span className="collapse_icon collapse_details">View Details</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>I possess skills in developing Cloud DFIR content and have successfully created multiple labs for AWS, GCP, and Azure. Developing this content involves deploying infrastructure in cloud environments using Infrastructure as Code (IaC) tools such as Terraform and CloudFormation, which I am proficient in using.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" eventKey="2">
                        <Accordion.Header>
                            <span className="collapse_num">03</span>
                            <span className="collapse_text">Speaker / Trainer / Educator</span>
                            <span className="collapse_icon collapse_less">View Less</span>
                            <span className="collapse_icon collapse_details">View Details</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>I'm a frequent speaker at security conferences and meetup groups. I have presented multiple cybersecurity talks and provided specialized training for 1000+ Professionals from my various courses and lab projects.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" eventKey="3">
                        <Accordion.Header>
                            <span className="collapse_num">04</span>
                            <span className="collapse_text">Open Source Contributor</span>
                            <span className="collapse_icon collapse_less">View Less</span>
                            <span className="collapse_icon collapse_details">View Details</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>I lead an open source project - Datadog AWS Threat Emulation Guide which aims to simplify the process of evaluating Datadog's Cloud SIEM security capabilities to detect AWS threats. I also I'm a major contributor to the learntocloud.guide which aims to provide an outline of skills you need to learn to get into Cloud Computing.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" eventKey="4">
                        <Accordion.Header>
                            <span className="collapse_num">05</span>
                            <span className="collapse_text">Security Mentorship</span>
                            <span className="collapse_icon collapse_less">View Less</span>
                            <span className="collapse_icon collapse_details">View Details</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>I'm the founder of Cyberwox Academy, an online cybersecurity community with over 2000 members, where I mentor the next generation of cybersecurity professionals. </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Services;