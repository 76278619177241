import { Routes, Route } from "react-router-dom"
import Home from './components/pages/Home'
import Resume from './components/pages/Resume'
import Shop from "./components/pages/Shop";
import Talks from "./components/pages/Talks";
import Blog from "./components/pages/Blog";
import Contact from "./components/pages/Contact";
import Blog7 from "./components/pages/BlogDetails/Blog7";
import Blog8 from "./components/pages/BlogDetails/Blog8";
import Blog9 from "./components/pages/BlogDetails/Blog9";
import Blog10 from "./components/pages/BlogDetails/Blog10";
import Blog11 from "./components/pages/BlogDetails/Blog11";
import Blog12 from "./components/pages/BlogDetails/Blog12";
import Blog13 from "./components/pages/BlogDetails/Blog13";

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();
  return (
    <div>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/resume" element={ <Resume/> } />
        <Route path="/shop" element={ <Shop/> } />
        <Route path="/talks" element={ <Talks/> } />
        <Route path="/blogs" element={ <Blog/> } />
        <Route path="/contact" element={ <Contact/> } />
        <Route path="/kubernetes-the-hard-way" element={ <Blog7/> } />
        <Route path="/exploiting-aws-3-defenders-perspective-flaws2cloud" element={ <Blog8/> } />
        <Route path="/cloud-security-things-to-know" element={ <Blog9/> } />
        <Route path="/exploiting-aws-2-attackers-perspective-flaws2cloud" element={ <Blog10/> } />
        <Route path="/ultraLearning" element={ <Blog11/> } />
        <Route path="/aws-incident-response-cloud-trail-logs" element={ <Blog12/> } />
        <Route path="/exploiting-aws-1-a-beginners-guide-flawscloud" element={ <Blog13/> } />
      </Routes>
    </div>
  );
}

export default App;
