import Layouts from "../Layouts/Layouts";
import BreadCrumb from "../Breadcrumb/Breadcrumb";

function Shop() {
    return (
       <Layouts>
           <BreadCrumb breadcrumbText={'CWX'}/>
           <div className={'py-[200px] flex items-center justify-center'}>
               <h2 className={'text-[72px] font-bold color'}>Coming Soon</h2>
           </div>
       </Layouts>
    )
}

export default Shop;
