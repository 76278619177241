import { NavLink } from 'react-router-dom';
import Button from '../UI/Button';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import MobileHeader from './MobileHeader';
import { useNavigate } from 'react-router-dom';

function Header() {

    const [navbar, setNavbar] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const navigate = useNavigate();

    const contactForm = () => {
        navigate("/contact");
    }

    const changeBackground = () => {
        if(window.scrollY >= 40){
          setNavbar(true)
        } else {
          setNavbar(false)
        }
      };

    window.addEventListener('scroll', changeBackground);

    const mobileMenuToggle = () => {
        setMobileMenu(!mobileMenu);
    }

    const overlayClose = () => {
        setMobileMenu(false);
    }

    return (
        <div className={navbar ? 'fixed w-full z-[99] py-[16px] bg-white transition-all duration-300 header scroll_down' : 'header absolute left-0 top-0 w-full pt-9 transition-all duration-300'}>
            <div className="container flex items-center justify-between mx-auto">
                <div>
                    {navbar ?
                        <Link to={'/'} className="header__logo">
                            <img src="logo-black.svg" alt="logo"/>
                        </Link> :
                        <Link to={'/'} className="header__logo">
                            <img src="logo.svg" alt="logo"/>
                        </Link>
                    }
                </div>
                <div className="header_nav !hidden lg:!flex">
                    <NavLink to={'/'}>Home</NavLink>
                    <NavLink to={'/resume'}>Resume</NavLink>
                    <NavLink to={'/shop'}>CWX</NavLink>
                    <NavLink to={'https://cyberwoxacademy.com/'} target="_blank">Academy</NavLink>
                    <NavLink to={'/talks'}>Talks</NavLink>
                    <NavLink to={'/blogs'}>Blog</NavLink>
                </div>
                <div className={'header__right relative'}>
                    <div onClick={mobileMenuToggle} className={`cursor-pointer lg:hidden header_mobile_menu h-10 w-10 rounded-full bg-white flex items-center justify-center ${navbar && '!bg-black'}`}>
                        {!mobileMenu ? <FaBars className={`fill-black !w-4 !h-4 ${navbar && '!fill-white'}`}/> :
                        <AiOutlineClose className={`fill-black !w-5 !h-5 ${navbar && '!fill-white'}`}/>}
                    </div>
                    <div className="desktop-btn hidden lg:block" onClick={contactForm}>
                        <Button buttonText={'Contact'}/>
                    </div>
                    {
                        mobileMenu ? <MobileHeader/> : ''
                    }
                </div>
            </div>
            <div className={mobileMenu ? 'fixed top-0 left-0 w-full h-screen bg-transparent z-[99]' : ''} onClick={overlayClose}></div>
        </div>
    )
}

export default Header;
