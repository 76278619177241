import CountUp from 'react-countup';
import HeroImage from '../../../assets/img/hero.png'

function Hero() {
    return (
        <div className="hero pt-[135px] lg:pt-40 pb-[65px] lg:pb-[130px]">
            <div className="container mx-auto flex-col lg:flex-row flex lg:gap-16">
            <div className="lg:ml-[122px] lg:max-w-[495px] lg:pt-[90px] relative">
                <h1
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="500"
                className="text-[48px] md:text-[72px] font-bold leading-[52px] md:leading-[80px] text-white mb-6"><span className="text-[#FF8677]">Hi, I'm</span> <br/> Day Johnson</h1>
                <div className=" text-white leading-8 text-base md:text-xl mb-7 md:mb-16 flex items-center !gap-5 sm:!gap-10">
                    <div className="h-[2px] w-[124px] md:w-[212px] relative before:absolute before:w-[calc(100%-10px)] before:h-full before:bg-[rgba(255,255,255,0.26)] before:content-[''] after:absolute after:right-0 after:-top-[3.6px] after:h-2.5 after:w-2.5 after:rounded-full after:content-[''] after:bg-[rgba(255,255,255,0.26)]"></div>
                    <span className="change_text__animate"></span>
                </div>
                <img src={HeroImage} className="lg:hidden" alt="hero"/>
                <div className="flex gap-4 lg:gap-5 mb-[60px] lg:mb-[96px] mt-10 lg:mt-0">
                <svg className="mt-2" width="38" height="19" viewBox="0 0 38 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 9.5L0 0V19L9.5 9.5ZM19 9.5L9.5 0V9.5V19L19 9.5ZM28.5 9.5L19 0V9.5V19L28.5 9.5ZM28.5 9.5V0L38 9.5L28.5 19V9.5Z" fill="white"/>
</svg>
                    <p className="text-base md:text-lg text-[#CDCDDA] leading-8">I'm on a mission to decode the fusion between engineering & life.</p>
                </div>
                <div className="flex-col sm:flex-row justify-between gap-4 lg:gap-0 hidden lg:flex">
                    <div className="lg:max-w-[150px] w-full mb-6 sm:mb-0">
                        <h3 className="font-semibold text-white text-4xl mb-2"><CountUp end={100} duration={1.5}/>+</h3>
                        <p className="text-base text-[#CDCDDA]">Detections Developed</p>
                    </div>
                    <div className="lg:max-w-[150px] w-full mb-6 sm:mb-0">
                        <h3 className="font-semibold text-white text-4xl mb-2"><CountUp end={1} duration={1.5}/>k+</h3>
                        <p className="text-base text-[#CDCDDA]">Trained Professionals</p>
                    </div>
                    <div className="lg:max-w-[150px] w-full mb-6 sm:mb-0">
                        <h3 className="font-semibold text-white text-4xl mb-2"><CountUp end={7} duration={1.5}/>+</h3>
                        <p className="text-base text-[#CDCDDA]">Cloud DFIR Labs Engineered</p>
                    </div>
                </div>
                <div className="text-[17px] font-medium text-white absolute rotate-90 top-40 -left-[176px] hidden lg:block"><span>SCROLL DOWN</span>
                    <svg className="absolute left-[180px] top-[-26px] -rotate-90" width="12" height="78" viewBox="0 0 12 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 78L11.7735 68L0.226498 68L6 78ZM7 1C7 0.447715 6.55229 2.41411e-08 6 0C5.44772 -2.41411e-08 5 0.447715 5 1L7 1ZM7 69L7 1L5 1L5 69L7 69Z" fill="url(#paint0_linear_24_532)"/>
<defs>
<linearGradient id="paint0_linear_24_532" x1="6" y1="76" x2="6.00003" y2="-0.499996" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="#090927"/>
</linearGradient>
</defs>
</svg>
</div>
            </div>
            <div className="hero__right">
                <img src={HeroImage} className="hidden lg:block" alt="hero"/>
                <div className="lg:mt-[56px] flex items-center lg:justify-end gap-8">
                    <p className="font-medium text-base text-white">Follow Me:</p>
                    <div className="flex items-start gap-[30px]">
                        <a href="https://www.linkedin.com/in/dayspringjohnson/?fbclid=IwAR1NBvGpFwDH2puRt89zN4TdYzNBzlXQ23T3BxutDbrhSTsrNpW1xG21NE4">
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" target={'_blank'} rel={'noreferrer'}>
    <path d="M3.98741 2.00089C3.98715 2.5313 3.77685 3.03987 3.40277 3.41473C3.02869 3.7896 2.52147 4.00005 1.99271 3.99978C1.46395 3.99952 0.95694 3.78856 0.583239 3.41332C0.209529 3.03808 -0.000268892 2.5293 2.58651e-07 1.99889C0.000269409 1.46849 0.210566 0.959918 0.584645 0.585048C0.958724 0.210189 1.46594 -0.000259745 1.9947 2.40602e-07C2.52347 0.000270226 3.03048 0.211229 3.40418 0.586468C3.77789 0.961708 3.98768 1.47049 3.98741 2.00089ZM4.04723 5.4807H0.0598117V18H4.04723V5.4807ZM10.3473 5.4807H6.37986V18H10.3074V11.4304C10.3074 7.77056 15.0624 7.43058 15.0624 11.4304V18H19V10.0704C19 3.90079 11.9622 4.13078 10.3074 7.16059L10.3473 5.4807Z" fill="white"/>
    </svg>
                        </a>
                        <a href="https://twitter.com/DayCyberwox?fbclid=IwAR1NBvGpFwDH2puRt89zN4TdYzNBzlXQ23T3BxutDbrhSTsrNpW1xG21NE4" target="_blank" rel={'noreferrer'}>
                        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_101_82)">
    <path d="M20.196 2.68105C19.4687 3.00005 18.6938 3.21149 17.886 3.31416C18.7171 2.81794 19.3514 2.03816 19.6496 1.09827C18.8747 1.56027 18.0192 1.88661 17.1074 2.06872C16.3716 1.28527 15.323 0.800049 14.179 0.800049C11.9594 0.800049 10.1725 2.60161 10.1725 4.81016C10.1725 5.12794 10.1994 5.43349 10.2654 5.72438C6.93238 5.56183 3.98316 3.96438 2.00194 1.53094C1.65605 2.13105 1.45316 2.81794 1.45316 3.55738C1.45316 4.94583 2.16816 6.17661 3.23394 6.88916C2.58982 6.87694 1.95794 6.68994 1.4226 6.39538C1.4226 6.40761 1.4226 6.42349 1.4226 6.43938C1.4226 8.38761 2.81227 10.0058 4.6346 10.3786C4.30827 10.4678 3.9526 10.5106 3.58349 10.5106C3.32682 10.5106 3.06771 10.4959 2.82449 10.4422C3.34394 12.0298 4.81794 13.197 6.5706 13.2349C5.2066 14.3019 3.47471 14.9448 1.59982 14.9448C1.27105 14.9448 0.955714 14.9301 0.640381 14.8898C2.41627 16.035 4.52094 16.6889 6.7906 16.6889C14.168 16.6889 18.2013 10.5778 18.2013 5.28072C18.2013 5.10349 18.1952 4.93238 18.1866 4.76249C18.9823 4.19783 19.6508 3.49261 20.196 2.68105Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_101_82">
    <rect width="21" height="16" fill="white" transform="translate(0 0.800049)"/>
    </clipPath>
    </defs>
    </svg>
                        </a>
                        <a href="https://www.instagram.com/DayCyberwox/?fbclid=IwAR2QMOkHInPPTOV_nygxdL4X3WjOIQ7niH5pdQASypCJGzqVxE_nwsY3qCc" target="_blank" rel={'noreferrer'}>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52502 0H13.275C16.3812 0 18.9 2.51887 18.9 5.625V12.375C18.9 15.4812 16.3812 18 13.275 18H6.52502C3.4189 18 0.900024 15.4812 0.900024 12.375V5.625C0.900024 2.51887 3.4189 0 6.52502 0ZM13.275 16.3125C15.4463 16.3125 17.2125 14.5462 17.2125 12.375V5.625C17.2125 3.45375 15.4463 1.6875 13.275 1.6875H6.52502C4.35377 1.6875 2.58752 3.45375 2.58752 5.625V12.375C2.58752 14.5462 4.35377 16.3125 6.52502 16.3125H13.275Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.40002 9C5.40002 6.51487 7.4149 4.5 9.90004 4.5C12.3852 4.5 14.4 6.51487 14.4 9C14.4 11.4852 12.3852 13.5 9.90004 13.5C7.4149 13.5 5.40002 11.4852 5.40002 9ZM7.08752 9C7.08752 10.5502 8.34977 11.8125 9.90004 11.8125C11.4503 11.8125 12.7125 10.5502 12.7125 9C12.7125 7.44862 11.4503 6.1875 9.90004 6.1875C8.34977 6.1875 7.08752 7.44862 7.08752 9Z" fill="white"/>
    <path d="M14.7376 4.76224C15.0687 4.76224 15.3372 4.49378 15.3372 4.16261C15.3372 3.83145 15.0687 3.56299 14.7376 3.56299C14.4064 3.56299 14.1379 3.83145 14.1379 4.16261C14.1379 4.49378 14.4064 4.76224 14.7376 4.76224Z" fill="white"/>
    </svg>
                        </a>
                        <a href="https://www.youtube.com/@DayCyberwox/videos" target="_blank" rel={'noreferrer'}>
                        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_101_90)">
    <path d="M0 12.3415C0 9.8076 0 7.27268 0 4.7388C0.0517331 4.31873 0.0931195 3.89658 0.156234 3.47858C0.385929 1.97005 1.18055 0.936419 2.66839 0.463579C3.67098 0.144904 4.7046 0.00315504 5.74237 0.00212038C10.6674 -0.00408759 15.5934 0.015571 20.5184 0.0466108C21.4102 0.0528188 22.2897 0.218365 23.133 0.529798C24.1811 0.916761 24.8981 1.62757 25.2426 2.70155C25.5075 3.52825 25.5851 4.38288 25.5923 5.23957C25.612 7.50652 25.6192 9.7745 25.5954 12.0414C25.5841 13.0658 25.4589 14.0828 25.1123 15.0606C24.6705 16.3073 23.822 17.1299 22.5691 17.5448C21.5944 17.8676 20.5846 17.9814 19.5685 17.9856C15.3678 18.0021 11.1671 17.9969 6.96534 18.0011C5.82204 18.0021 4.68495 17.94 3.57475 17.6359C2.53595 17.3513 1.62856 16.8619 0.997413 15.9525C0.512157 15.252 0.284532 14.4563 0.156234 13.6276C0.0900155 13.2003 0.0506984 12.7698 0 12.3415ZM9.85929 8.87227C9.85929 9.75587 9.85204 10.6395 9.86549 11.522C9.8686 11.7155 9.90585 11.9276 9.99483 12.0952C10.2131 12.506 10.6881 12.6012 11.1681 12.3518C12.8805 11.461 14.5898 10.566 16.3011 9.67517C16.6332 9.50238 16.8722 9.27372 16.8712 8.8702C16.8701 8.46772 16.6342 8.24009 16.299 8.0673C14.5991 7.18991 12.9033 6.30424 11.2043 5.42478C10.4635 5.04092 9.86136 5.41029 9.86032 6.24734C9.85722 7.12162 9.85929 7.99695 9.85929 8.87227Z" fill="white"/>
    <path d="M9.85928 8.8723C9.85928 7.99698 9.85721 7.12165 9.85928 6.24737C9.86135 5.40929 10.4635 5.04095 11.2033 5.42481C12.9022 6.30531 14.598 7.18994 16.298 8.06734C16.6322 8.24012 16.8691 8.46775 16.8701 8.87023C16.8712 9.27375 16.6311 9.50241 16.3 9.6752C14.5877 10.566 12.8784 11.461 11.1671 12.3519C10.688 12.6012 10.2131 12.5071 9.99378 12.0953C9.9048 11.9277 9.86755 11.7145 9.86445 11.5221C9.85203 10.6385 9.85928 9.75487 9.85928 8.8723Z" fill="#100F44"/>
    </g>
    <defs>
    <clipPath id="clip0_101_90">
    <rect width="25.611" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Hero;
