import ReactPlayer from "react-player";

function BlogDetailsVideo({link}) {
    return (
        <div className={'!pb-[56.8%] relative'}>
            <ReactPlayer className="!h-full absolute top-0 left-0 !w-full" width='100%' url={link} controls={true} />
        </div>
    )
}

export default BlogDetailsVideo;
