import Blog1 from '../../assets/img/blogs/1.png'
import Blog2 from '../../assets/img/blogs/2.png'
import Blog3 from '../../assets/img/blogs/3.png'
import Blog4 from '../../assets/img/blogs/4.png'
import Blog5 from '../../assets/img/blogs/5.png'
import Blog6 from '../../assets/img/blogs/6.png'
import Blog7 from '../../assets/img/blogs/7.png'
import Blog8 from '../../assets/img/blogs/8.png'
import Blog9 from '../../assets/img/blogs/9.png'
import Blog10 from '../../assets/img/blogs/10.png'
import Blog11 from '../../assets/img/blogs/11.png'
import Blog12 from '../../assets/img/blogs/12.png'
import SecondaryButton from '../UI/SecondaryButton'
import BlogItem from './BLogItem'
import { useState } from 'react'

const imagePerRow = 8;

function Blogs() {
    const [next, setNext] = useState(imagePerRow);

    const blogs = [
        {
            blogTitle: 'An Adventure in Google Cloud threat detection',
            blogImg: Blog1,
            blogLink: 'https://securitylabs.datadoghq.com/articles/google-cloud-threat-detection/',
            blogDate: 'Apr 24, 2023',
            target: true,
        },
        {
            blogTitle: 'AWS threat emulation and detection validation with Stratus Red Team and Datadog Cloud SIEM',
            blogImg: Blog2,
            blogLink: 'https://www.datadoghq.com/blog/aws-threat-emulation-detection-validation-datadog/',
            blogDate: 'Jul 25, 2023',
            target: true,
        },
        {
            blogTitle: 'Monitor 1Password with Datadog Cloud SIEM',
            blogImg: Blog3,
            blogLink: 'https://www.datadoghq.com/blog/monitor-1password-datadog-cloud-siem/',
            blogDate: 'Jul 27, 2023',
            target: true,
        },
        {
            blogTitle: 'Monitor Cloudflare Zero Trust with Datadog Cloud SIEM',
            blogImg: Blog4,
            blogLink: 'https://www.datadoghq.com/blog/monitor-cloudflare-zero-trust/',
            blogDate: 'Jul 27, 2023',
            target: true,
        },
        {
            blogTitle: 'Datadog Spotlight: Dayspring Johnson',
            blogImg: Blog5,
            blogLink: 'https://www.datadoghq.com/blog/pup-culture/datadog-spotlight-dayspring-johnson/',
            blogDate: 'Feb27, 2023',
            target: true,
        },
        {
            blogTitle: 'Kubernetes The Hard Way',
            blogImg: Blog6,
            blogLink: '/kubernetes-the-hard-way',
            blogDate: 'Jun 20, 2022',
            target: false,
        },
        {
            blogTitle: 'Exploiting AWS 3 - Defenders Perspective (Flaws2.Cloud)',
            blogImg: Blog7,
            blogLink: '/exploiting-aws-3-defenders-perspective-flaws2cloud',
            blogDate: 'Jun 10, 2022',
            target: false,
        },
        {
            blogTitle: 'Cloud Security Things To Know',
            blogImg: Blog8,
            blogLink: '/cloud-security-things-to-know',
            blogDate: 'May 23, 2022',
            target: false,
        },
        {
            blogTitle: 'Exploiting AWS 2 - Attackers Perspective (Flaws2.Cloud)',
            blogImg: Blog9,
            blogLink: '/exploiting-aws-2-attackers-perspective-flaws2cloud',
            blogDate: 'May 9, 2022',
            target: false,
        },
        {
            blogTitle: 'UltraLearning',
            blogImg: Blog10,
            blogLink: '/ultraLearning',
            blogDate: 'May 1, 2022',
            target: false,
        },
        {
            blogTitle: 'AWS Incident Response - Cloud Trail Logs',
            blogImg: Blog11,
            blogLink: '/aws-incident-response-cloud-trail-logs',
            blogDate: 'Apr 12, 2022',
            target: false,
        },
        {
            blogTitle: 'Exploiting AWS 1 - A Beginners Guide (Flaws.Cloud)',
            blogImg: Blog12,
            blogLink: '/exploiting-aws-1-a-beginners-guide-flawscloud',
            blogDate: 'Apr 12, 2022',
            target: false,
        },
    ]

    const handleMoreImage = () => {
        setNext(next + imagePerRow);
    };

    return (
        <div className="container mx-auto pt-[100px] pb-[100px] lg:pb-[180px]">
            <div className="grid md:grid-cols-2 gap-7">
            {blogs?.slice(0, next)?.map(el => (
                <BlogItem blogTitle={el.blogTitle} blogImg={el.blogImg} blogLink={el.blogLink} blogDate={el.blogDate} blogViews={el.blogViews} target={el.target} />
            ))}
            </div>
            <div className="text-center">
                {next < blogs?.length && (
                    <div className="inline-flex pt-10" onClick={handleMoreImage}>
                        <SecondaryButton buttonText={'Load More'}/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Blogs;
