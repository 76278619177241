function Projects() {
    return(
        <div className="container mx-auto pb-[100px] lg:pb-[180px]">
            <h2 data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500" className="lg:text-[48px] text-[32px] font-semibold text-[#000000] text-center mb-8">Open Source Projects</h2>
            <div className="max-w-[760px] w-full mx-auto">
                <div data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" className="mb-8 border border-[#E8E8E8] rounded-[12px] py-10 px-6 md:!p-10">
                    <h3 className="text-xl md:text-[24px] leading-8 font-semibold text-[#FF8677] md:leading-[38px] mb-0.5">
                        <a href="https://github.com/DataDog/cloud-siem-aws-threat-emulation/" target={'_blank'} rel={'noreferrer'}>Datadog AWS Threat Emulation Guide</a></h3>
                    <span className="text-sm lg:text-base leading-4 lg:leading-[18px] text-[#A5A5A5] block pb-2 mt-2 md:!mt-0">Tech stack: Bash, AWS, Datadog, Terrraform</span>
                    <p className="text-sm lg:text-base leading-[22px] lg:leading-[26px] text-[#272728] mt-3 lg:mt-6">This guide simplifies the process of evaluating Datadog's Cloud SIEM security capabilities to detect AWS threats and alert in real-time by simulating attacks using Stratus Red Team.</p>
                </div>
                <div data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="500" className="mb-0 border border-[#E8E8E8] rounded-[12px] py-10 px-6 md:!p-10">
                    <h3 className="text-xl md:text-[24px] leading-8 font-semibold text-[#FF8677] md:leading-[38px] mb-0.5">
                        <a href="https://learntocloud.guide/" target={'_blank'} rel={'noreferrer'}>LearnToCloud</a></h3>
                    <p className="text-sm lg:text-base leading-[22px] lg:leading-[26px] text-[#272728] mt-3 lg:mt-6">This guide aims to provide an outline of skills you need to learn to get into Cloud Computing. Covering everything from Linux fundamentals, Networking fundamentals, Programming, Git, Cloud Platforms, Devops & Cloud security.</p>
                </div>
            </div>
        </div>
    )
}

export default Projects;
