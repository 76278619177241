import React from 'react';
import ResumeExperience from "./ResumeExperience";


function ResumeSection() {
  return (
    <>
      <div className={'container pt-[100px] lg:pt-[140px] pb-8'}>
        <div className={'grid grid-flow-row md:grid-cols-3'}>
          <div className={'custom_border__resume xm:mb-20 md:mb-0'}>
            <h2 data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="500" className={'text-[32px] lg:text-[48px] font-semibold text-[#000000] mb-6 md:mb-0'}>Experience</h2>
          </div>
          <div className={'col-span-2'}>
            <ResumeExperience/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeSection;