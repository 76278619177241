import Button from "../../UI/Button";
import { useNavigate } from 'react-router-dom';

function About() {

    const navigate = useNavigate();

    const contactForm = () => {
        navigate("/contact");
    }

    return (
        <div className="pt-[70px] pb-[90px] lg:py-[180px]">
            <div className="container mx-auto lg:flex gap-40 justify-between">
                <h2 data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500" className="text-[40px] lg:text-[72px] text-[#272728] font-semibold leading-[44px] lg:leading-[80px] !mb-5 lg:!mb-0 text-center lg:text-left">About Me</h2>
                <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500" className="text-[15px] lg:text-base text-[#9E9FA5] lg:max-w-[660px]">
                    <p className="mb-6 lg:!mb-10 leading-[24px] lg:leading-[26px]">Over the last three years, I have specialized in various domains of cybersecurity, particularly in detection engineering for cloud environments and SaaS applications. My cybersecurity engineering expertise has been utilized by Fortune 500 companies and major tech firms worldwide, and I have designed cloud DFIR-based labs and training for well-known learning platforms such as LetsDefend, Blue Team Labs Online by Security Blue Team, and LinkedIn Learning to teach core skills in Cloud Detection Engineering, Incident Response, and Forensics.</p>
                    <p className="mb-8 lg:!mb-10 leading-[24px] lg:leading-[26px]">Apart from being an engineer, I am a digital creative who produces content on YouTube, LinkedIn, Instagram, and Twitter, and I have a total audience of over 50k followers. I founded Cyberwox Academy, an online cybersecurity community with over 2000 members, where I mentor the next generation of cybersecurity professionals. Furthermore, I am passionate about sharing my work and research and have spoken at conferences and platforms such as fwd:cloudsec, Texas Cyber Summit, Microsoft Reactor, Cloud Security Podcast and many others.</p>
                    <div onClick={contactForm} className="flex justify-center lg:!justify-start about-btn">
                        <Button buttonText={'Contact'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;