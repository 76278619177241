import React from "react";
import "./custom_resumr_experience__css.css";

function ResumeExperience() {
  return (
    <div>
      {/*Items*/}
      <div className={"custom__hover pb-[50px]"}>
        <span className={"custom_after mb-3"}>March 2022 - Present</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px] text-[#272728]"}>
          Datadog
        </h4>
        <div>
          <div className={"custom__hover custom__group__hover pb-6 mt-8"}>
            <span className={"custom_after mb-3"}>Aug 2023 - Present</span>
            <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
              Detection Engineer II
            </p>
            <div className={"mt-8"}>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Leading SaaS & Cloud platform integration development & onboarding for threat detection use cases
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Collaborating with product management, software engineering & security research to develop new detection capabilities
              </li>
            </div>
          </div>
          <div className={"custom__hover custom__group__hover"}>
            <span className={"custom_after mb-3"}>March 2022 - Aug 2023</span>
            <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
              Detection Engineer
            </p>
            <div className={"mt-8"}>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Developing out-of-the-box (OOTB) threat detection content for cloud environments
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Researching vulnerabilities in multi-cloud sources and various SaaS platforms
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Configuring security logging and pipeline engineering for audit log sources
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Threat Hunting for undiscovered compromises across customer environments
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Writing playbooks and workflows for automated detection triage and response
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Establishing standards, processes, and procedures to improve Detection Engineering
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Designing cloud security workshops and demos for Security and DevOps conferences
              </li>
            </div>
          </div>
        </div>
      </div>
      {/*Items*/}
      <div className={'custom__hover pb-[50px]'}>
        <span className={"custom_after mb-3"}>June 2021 to March 2022</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          Optiv
        </h4>
        <div>
          <div className={"custom__hover custom__group__hover pb-6 mt-8"}>
            <span className={"custom_after mb-3"}>November 2021 to March 2022</span>
            <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
              Threat Analyst II
            </p>
            <div className={"mt-8"}>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Consulted for Fortune500 Client on threat analysis and security operations
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Conducted threat hunts based on client-provided hypothesis
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Utilized multiple tools including Splunk, Azure Security Center, & Crowdstrike for security investigations
              </li>
            </div>
          </div>
          <div className={"custom__hover custom__group__hover"}>
            <span className={"custom_after mb-3"}>June 2021 to November 2021</span>
            <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
              Threat Analyst I
            </p>
            <div className={"mt-8"}>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Triaged SIEM alerts through Splunk and investigated security anomalies for Fortune50 Client
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Utilized Splunk Phantom SOAR & Cortex XSOAR to review event details and automate response actions
              </li>
              <li
                  data-aos="fade-up"
                  data-aos-duration="300"
                  className={
                    "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
                  }
              >
            <span className={"mt-1"}>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                    cx="7"
                    cy="7"
                    r="5"
                    fill="white"
                    stroke="#FF8677"
                    strokeWidth="4"
                />
              </svg>
            </span>
                Investigated malicious activities in remote endpoints using Crowdstrike, Tanium & TrendMicro EDRs
              </li>
            </div>
          </div>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[50px]"}>
        <span className={"custom_after mb-3"}>Jun 2022 - April 2023</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
        Collin College
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
        Cybersecurity Tutor
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            I was the first tutor for the FLEXTech program at Collin College with responsibilities involving planning and designing the tutoring program for IT/Networking & Security Fundamentals. The end goal of this program is to help students learn core skills and connect them with opportunities for IT & Cybersecurity internships.
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[50px]"}>
        <span className={"custom_after mb-3"}>March 2021 to June 2021</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
        Cyber Defense Labs
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
        Cybersecurity Analyst
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Monitored AlienVault and Securonix for security events, alerts, threats, intrusions, and/or compromises.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Utilized Cylance and Carbon Black for Incident Response, endpoint monitoring, quarantine, and sandbox analysis.
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[50px]"}>
        <span className={"custom_after mb-3"}>September 2020 to March 2021</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
        BenefitMall
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
        Cybersecurity Analyst
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Monitored Azure cloud environment for security events, alerts, threats, intrusions, and/or compromises.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Analyzed email headers and other artifacts to respond to phishing campaigns with malicious indicators.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#FF8677"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Created and maintained mail flow rules on MS Exchange server to block ongoing phishing campaigns.
          </li>
        </div>
      </div>
    </div>
  );
}

export default ResumeExperience;
