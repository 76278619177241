import About from "../Home/About/About";
import Courses from "../Home/Courses/Courses";
import Hero from "../Home/Hero/Hero";
import Services from "../Home/Services/Services";
import Videos from "../Home/Videos/Videos";
import Sponsors from "../Home/sponsors/Sponsors";
import Layouts from "../Layouts/Layouts";

function Home() {
    return (
        <Layouts>
        <Hero/>
        <Sponsors/>
        <About/>
        <Services/>
        <Courses/>
        <Videos/>
        </Layouts>
    )
}

export default Home;