import ReactAudioPlayer from "react-audio-player";

function BlogDetailsAudio({link}) {
    return (
        <div className={'!pt-5 !px-6 !pb-6 border border-[#C6C6C6] rounded-[12px] max-w-[768px] w-full'}>
            <h6 className={'text-[17px] font-semibold text-[#272728] !mb-4 text-center uppercase'}>Play This Article</h6>
            <ReactAudioPlayer
                src={link}
                autoPlay={false}
                controls
                className={'w-full'}
            />
        </div>
    )
}

export default BlogDetailsAudio;
