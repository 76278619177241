import Layouts from "../Layouts/Layouts";
import BreadCrumb from "../Breadcrumb/Breadcrumb";
import TalkVideos from "../Talks/TalkVideos";

function Talks() {
    return (
        <Layouts>
            <BreadCrumb breadcrumbText={'Talks'}/>
            <TalkVideos/>
        </Layouts>
    )
}

export default Talks;